.App {
  text-align: center;
}

.contont-top {
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

a{
  text-decoration:none;  
  }